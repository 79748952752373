import React, { createContext,useContext, useState } from 'react';
import LogoSection from './LogoSection';
import { useForm, Controller } from 'react-hook-form';
import { Box,Button, Paper, TextField ,Link, Typography, Container, Grid, CssBaseline,} from '@mui/material'
import SearchAccount from './SearchAccount'
import ResetPassword from './ResetPassword'
import SuccessDialog from './SuccessDialog'
import OtpValidation from './OtpValidation'
import FailureDialog from './FailureDialog'

const ForgotPasswordContext = createContext();
const ForgotPassword = () => {
    const [step,setStep] = useState(0);

    const userCredentialsInitial = {
        user_id:'',
        email:'',
        phone:'',
        password:'',
        confirmPassword:'',
        otp:'',
    }
    const [userCredentials,setUserCredentials] = useState(userCredentialsInitial);
    const handleNext =()=>{
        setStep(step+1);
    }
  return (
    <Container component="main" maxWidth="lg">
        <Box >
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '90vh' }}>
                <CssBaseline />

                <Grid
                    item
                    // xs={12}
                    // sm={8}
                    // md={5}
                    component={Paper}
                    elevation={6}
                    square
                    sx={{
                        width: { xs: "100%", sm: "75%", md: "50%" },  // Responsive widths
                        maxWidth: "600px",  // You can add a maximum width to control it on larger screens
                        // padding: 2,  // Add padding to improve spacing
                        borderRadius:'1rem'
                    }}
                >
                    <LogoSection/>
                    <Paper
                        sx={{
                            my: '3%',
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p:'3%',
                            borderRadius:'.75rem'
                        }}
                    >
                        <Box sx={{width:'100%'}}>         
                            <ForgotPasswordContext.Provider value={{step,setStep,handleNext,userCredentials,setUserCredentials,userCredentialsInitial}} >     
                                {step==0 && <SearchAccount/>}
                                {step==1 && <OtpValidation/>}
                                {step==2 && <ResetPassword/>}
                                {step==3 && <SuccessDialog/>}
                                {step==4 && <FailureDialog/>}
                            </ForgotPasswordContext.Provider>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        
    </Container>
  )
}

export default ForgotPassword
export {ForgotPasswordContext}