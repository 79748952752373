"use client"

import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ForgotPasswordContext } from '.';

const OtpValidation = () => {
    const {step,nextStep,handleNext,userCredentials,setUserCredentials,userCredentialsInitial} = useContext(ForgotPasswordContext);
    const{register,handleSubmit,control,getValues,formState:{errors},clearErrors,reset,setValue}= useForm();
    
    const [error,setError] = useState('');
    const inputChangeHandler =(e)=>{
        setError('');
        setUserCredentials((pre)=>{
            return {...pre,[e.target.name]:e.target.value}
        })
    }
    const validateOtp = async()=>{
        let data ={
            otp:userCredentials.otp,
            user_id:userCredentials.user_id
        }
        // try {
        //     const response= await api.post('/rest-api/auth/password-change',data);
        // if(response.data.status){
        //     // console.log(response);
        //     handleNext();

        // }
        // } catch (error) {
        //     const errorMessage = error.response.data.message;
        //     setError(errorMessage);
        // }
    }
    return (
    <>
    <Box sx={{p:'2%', borderBottom:'2px solid',display:'flex',gap:'5%'}}>
        {/* <img src='/aaomilkar_logo.png' alt='amkm_logo'  style={{width:30,height:10}}/> */}
        <Typography sx={{flex:1,fontSize:'x-large',fontWeight:"bold"}}>Validate Otp</Typography>
    </Box>
    <Box sx={{textAlign:'center', mt:'2%'}}>
        <Typography sx={{text:'x-small', color:'red'}}>{error && error}</Typography>
    </Box>
    <Box component='form' sx={{p:'5%', textAlign:'center'}} onSubmit={handleSubmit(validateOtp)}>
        <TextField 
            
            size='small'
            // required
            label='Enter Otp*'
            name='otp'
            value={userCredentials.otp}
            onChange={inputChangeHandler}
            inputProps={{
                ...register(
                    'otp',{
                        required:'empty not allowed',
                    }
                )
            }}
            error={errors.otp && errors.otp}
            helperText={errors.otp && errors.otp?.message}
        />
        <Box sx={{my:'2%', textAlign:'center'}}>
            <Button variant='contained' color='primary' size='small' type='submit'>validate</Button>
        </Box>
        {/* <Box sx={{"flex justify-between">
            <Link href='/account/forgot-password' sx={{'text-[#0866ff]'>Forgot Password</Link>
            <Typography>If not already registered ?...<span><Link href="/account/register" sx={{'text-[#0866ff]'>Sign Up</Link></span></Typography>
        </Box> */}
    </Box>
    </>
  )
}

export default OtpValidation