"use client"
import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SuccessDialog = () => {
  const [seconds, setSeconds] = useState(5);
  const router = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      router('/login');
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [router]);

  const handleGoToLogin = () => {
    router('/login');
  };

  return (
    <Dialog open>
        <Box sx={{textAlign:'center', p:'5%'}}>
            <Box sx={{display:'flex', justifyContent:'center'}}>
            <img style={{width:80, height:'auto'}} src='/check-mark.gif' alt='success_symbol'/>
            </Box>
            <Typography sx={{fontWeight:'bold' ,color:'green'}}>Password Reset Successfully</Typography>
            <Box sx={{my:'5%'}}>
                <Button size='small' variant='contained' onClick={handleGoToLogin}>
                    Go to Login Page
                </Button>
            </Box>
            <Typography sx={{fontSize:"small"}}>You will be redirected to the login page automatically in <span sx={{color:'#0866ff'}}>{seconds}</span> seconds...</Typography>
        </Box>
      
    </Dialog>
  );
};

export default SuccessDialog;
