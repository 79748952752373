import { Close } from '@mui/icons-material';
import { Box, Card, Chip, Dialog, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';

const ViewDetails = ({selectedBookingDetails,setSelectedBookingDetails}) => {
    const [dialogOpen,setDialogOpen] = useState(true);
  return (
    <Dialog
        open={dialogOpen}
        fullWidth={true} 
        maxWidth="md" 
        PaperProps={{
        sx: {
            width: 'max-content', 
            maxWidth: '100%' 
        },
        }}
    >
        {/* <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%'}}>
            <Typography variant={'h5'}>Booking Detail's </Typography>
            <IconButton size='small' color='error' onClick={()=>setSelectedBookingDetails(null)}><Close/></IconButton>
        </Card> */}
        <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
            <Typography sx={{fontWeight:600,fontSize:'larger',color:'#464166'}}>Booking Detail's</Typography>
            <IconButton size='small' color='error' onClick={()=>setSelectedBookingDetails(null)}><Close/></IconButton>
        </Card>
        <DialogContent>
            <Box textAlign={'center'}>
                <Typography fontWeight={'bold'}>Booking Number: <Box component={'span'} sx={{color:'#1976d2'}}>{selectedBookingDetails.bookingNumber}</Box></Typography>
            </Box>
            <Box sx={{display:'flex',my:'2%',alignItems:'flex-end'}}>
                <Box sx={{width:'28%'}}>
                    <Typography sx={{fontWeight:'bold'}}>Service Status:</Typography>
                </Box>
                <Typography sx={{textTransform:'uppercase',color:'#778899',fontSize:'smaller'}}>{selectedBookingDetails.bookingStatus}</Typography>
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Service Detail's :</Typography>
                {/* <Paper sx={{p:'2%'}}> */}
                    {/* <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Requested Services</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Box sx={{display:'flex',width:'68%',flexWrap:'wrap'}}>
                        {
                            selectedBookingDetails.bookingItems.map((item,index)=>(
                                // <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{item.serviceName}{selectedBookingDetails.bookingItems.length>1 && index<selectedBookingDetails.bookingItems.length-1 && ','}</Typography>
                                <Chip key={index} label={item.serviceName} size='small' sx={{my:'1%'}}/>    
                            ))
                        }
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Time Slot</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{selectedBookingDetails.bookingStartTime} - {selectedBookingDetails.bookingEndTime}</Typography>
                    </Box> */}
                    <TableContainer component={Paper}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:'bold'}}>Requested Services</TableCell>
                                    <TableCell sx={{fontWeight:'bold',textAlign:'center'}}>Time Slot</TableCell>
                                    <TableCell sx={{fontWeight:'bold',textAlign:'center'}}>Price (In Rs.)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedBookingDetails.bookingItems.map((item,index)=>(
                                        <TableRow key={index}>
                                            <TableCell sx={{fontWeight:'500'}}>{item.serviceName}</TableCell>
                                            <TableCell sx={{}}>{selectedBookingDetails.bookingStartTime} - {selectedBookingDetails.bookingEndTime}</TableCell>
                                            <TableCell sx={{textAlign:'right'}}>{item.price}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                    
                                    <TableCell sx={{}}></TableCell>
                                    <TableCell sx={{fontWeight:'600'}}>SubTotal</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>{selectedBookingDetails.subTotal}</TableCell>
                                </TableRow>
                                <TableRow>
                                    
                                    <TableCell sx={{}}></TableCell>
                                    <TableCell sx={{}}>Visiting Charge</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>{selectedBookingDetails?.chargeDetails?.vistingCharge}</TableCell>
                                </TableRow>
                                {selectedBookingDetails?.chargeDetails?.additionalCharge>0 && <TableRow>
                                    
                                    <TableCell sx={{}}></TableCell>
                                    <TableCell sx={{}}>Additional Charge</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>{selectedBookingDetails?.chargeDetails?.additionalCharge}</TableCell>
                                </TableRow>}
                                <TableRow>
                                    
                                    <TableCell sx={{}}></TableCell>
                                    <TableCell sx={{}}>Tax</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>{selectedBookingDetails.tax}</TableCell>
                                </TableRow>
                                <TableRow>
                                    
                                    <TableCell sx={{}}></TableCell>
                                    <TableCell sx={{fontWeight:'600'}}>Total</TableCell>
                                    <TableCell sx={{textAlign:'right',fontWeight:'600'}}>{parseFloat(parseFloat(selectedBookingDetails?.subTotal) + parseFloat(selectedBookingDetails?.chargeDetails?.additionalCharge) +parseFloat(selectedBookingDetails?.tax) + parseFloat(selectedBookingDetails?.chargeDetails?.vistingCharge)).toFixed(2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                {/* </Paper> */}
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Customer Detail's :</Typography>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{selectedBookingDetails.add_customerFullName}</Typography>    
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{selectedBookingDetails.add_mobileNumber}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Address</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{selectedBookingDetails.add_address}</Typography>         
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Landmark</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{selectedBookingDetails.add_landmark}</Typography>         
                    </Box>
                </Paper>
            </Box>
            <Box sx={{my:'2%'}}>
                <Typography sx={{fontWeight:"bold"}}>Provider Detail's <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
                <Paper sx={{p:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{selectedBookingDetails?.providerName}</Typography>    
                    </Box>
                    {/* <Box sx={{display:'flex',alignItems:'flex-end'}}>
                        <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
                        <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
                        <Typography fontWeight={'medium'} sx={{fontSize:'small',width:'68%'}}>{selectedBookingDetails?.provider?.mobileNumber}</Typography>         
                    </Box> */}
                </Paper>
            </Box>
        </DialogContent>
    </Dialog>
  );
}

export default ViewDetails;
