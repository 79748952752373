"use client"
import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form';
import { ForgotPasswordContext } from '.';


const ResetPassword = () => {
    const {step,setStep,handleNext,userCredentials,setUserCredentials,userCredentialsInitial} = useContext(ForgotPasswordContext);

    const{register,handleSubmit,control,formState:{errors},clearErrors,reset}= useForm({
        mode:'onChange'
    });
    
    const inputChangeHandler=(e)=>{
        setUserCredentials(pre=>({...pre,[e.target.name]:e.target.value}));
    }
    const submitHandler =async()=>{
        // console.log("credentials",userCredentials);
        const data = {
            user_id:userCredentials.user_id,
            password:userCredentials.password
        }
        // console.log(" data to be send:",data)
        // try {
        //     const response = await api.put('/rest-api/auth/password-change',data);
        // // console.log("loginresponse",response);
        // if(response.data.status){
        //     handleNext();
        // }
        // } catch (error) {
        //     setStep(4);
        //     console.log("error:",error.response)
        // }
        
    }
  return (
    <>
    <Box sx={{p:'2%', borderBottom:'2px solid',display:'flex',gap:'5%'}}>
        {/* <img src='/aaomilkar_logo.png' alt='amkm_logo' style={{width:30,height:10}}/> */}
        <Typography sx={{flex:1,fontSize:'x-large',fontWeight:"bold"}}>Change Password</Typography>
    </Box>
    <Box component='form' sx={{p:'5%'}} onSubmit={handleSubmit(submitHandler)}>
        <Box sx={{display:'flex',alignItems:'center', gap:'2%'}}>
            <TextField 
                sx={{my:'2%'}}
                type='password'
                size='small'
                // required
                label='Enter Password*'
                autoComplete='off'
                name='password'
                value={userCredentials.password}
                onChange={inputChangeHandler}
                inputProps={{
                    ...register(
                        'password',{
                            required: "Password should not be empty",
                            pattern:{
                                value:/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&]).{6,32}$/,
                                message:"should be min.6 digit with  Uppercase,lowercase,special symbol and numeric"
                            }
                        }
                    )
                }}
                error={errors.password && errors.password}
                // helperText={errors.password && errors.password?.message}
            />
            <Typography sx={{fontSize:'x-small',color:'red'}}>{errors.password && errors.password?.message}</Typography>
        </Box>
        <Box sx={{display:'flex', alignItems:'center' ,gap:'2%'}}>
            <TextField 
                sx={{my:'2%'}}
                autoComplete='off'
                size='small'
                label='Confirm Password*'
                name='confirmPassword'
                value={userCredentials.confirmPassword}
                onChange={inputChangeHandler}
                inputProps={{
                    ...register(
                        'confirmPassword',{
                            required:'empty not allowed',
                            validate:(value)=>{
                                if (userCredentials.password !== value) {
                                    return 'password mismatch';
                                }
                                return true;
                            },
                        }
                    )
                }}
                error={errors.confirmPassword && errors.confirmPassword}
                // helperText={errors.confirmPassword && errors.confirmPassword?.message}
            />
            <Typography sx={{fontSize:'x-small',color:'red'}}>{errors.confirmPassword && errors.confirmPassword?.message}</Typography>
           {userCredentials.confirmPassword === userCredentials.password && !errors.password&& userCredentials.confirmPassword && !errors.confirmPassword && <img src='/Success-Transparent.png' style={{width:30,height:30}} alt='success_symbol'/>}
        </Box>
        <Box sx={{my:'2%' ,textAlign:'center'}}>
            <Button variant='contained' color='primary' type='submit'>Reset Password</Button>
        </Box>
    </Box>
    </>
  )
}

export default ResetPassword