"use client"
import React, { useContext } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { ForgotPasswordContext } from '.';

const FailureDialog = () => {
    const {setStep,setUserCredentials,userCredentialsInitial} = useContext(ForgotPasswordContext);

    const handleGoTo = () => {
        setStep(0);
        setUserCredentials(userCredentialsInitial);
    };

  return (
    <Dialog open fullWidth>
        <Box sx={{textAlign:'center',p:'5%'}}>
            <Box sx={{display:'flex',justifyContent:'center'}}>
                <img style={{width:80 ,height:'auto'}} src='/error.png' alt='success_symbol'/>
            </Box>
            <Typography sx={{fontWeight:'bold',color:'red',my:'3%'}}>Password Reset failed</Typography>
            <Typography sx={{fontWeight:'bold',color:'red'}}>Try Again after sometime</Typography>
            <Box sx={{my:'5%'}}>
                <Button size='small' variant='contained' onClick={handleGoTo}>
                    Try again
                </Button>
            </Box>
            {/* <Typography className="text-sm">You will be redirected to the login page automatically in <span className='text-[#0866ff]'>{seconds}</span> seconds...</Typography> */}
        </Box>
      
    </Dialog>
  );
};

export default FailureDialog;
