
import { Box, Button, TextField, Typography,Link } from '@mui/material'
import {useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ForgotPasswordContext } from '.'
import { Link as RouterLink,useNavigate } from 'react-router-dom';

const SearchAccount = () => {
    const {handleNext,userCredentials,setUserCredentials,userCredentialsInitial} = useContext(ForgotPasswordContext);
    const{register,handleSubmit,control,getValues,formState:{errors},clearErrors,reset,setValue,watch}= useForm();
    
    const [error,setError] = useState('');
    const inputChangeHandler =(e)=>{
        setError('');
        setUserCredentials((pre)=>{
            return {...pre,[e.target.name]:e.target.value}
        })
    }
    const submitHandler =async()=>{
        // console.log("userCredentials",userCredentials);
        // try {
        //     const response = await api.get(`/rest-api/auth/password-change?email=${userCredentials.email}&contact=${userCredentials.phone}`);
        //     // console.log("account search response:",response);
        //     if(response.data.status){
        //         setUserCredentials(pre=>({...pre,user_id:response.data.user_id}));
        //         handleNext();
        //     }
        //     handleNext();
        // } catch (error) {
        //     const errorMessage = error.response.data.message;
        //     setError(errorMessage);
        // }
    }
  return (
    <>
    <Box sx={{p:'2%', borderBottom:'2px solid',display:'flex',gap:'5%'}}>
        {/* <Image src='/aaomilkar_logo.png' alt='amkm_logo' width={30} height={10}/> */}
        <Typography sx={{flex:1,fontSize:'x-large',fontWeight:"bold"}}>Search Your Account</Typography>
    </Box>
    <Box sx={{textAlign:'center', mt:'2%'}}>
        <Typography sx={{text:'x-small', color:'red'}}>{error && error}</Typography>
    </Box>
    <Box component='form' className='p-[5%]' onSubmit={handleSubmit(submitHandler)}>
        <TextField
            fullWidth
            sx={{my:'2%'}}
            size='small'
            name='email'
            label='Enter email'
            disabled={watch('phoneNumber')}
            {
                ...register(
                    'email', {
                        pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                            message: "Enter a valid email"
                        },
                        validate: (value) => {
                            const phone = getValues('phoneNumber');
                            if (!value && !phone) {
                                return 'Either email or phone number is required';
                            }
                            return true;
                        }
                    }
                )
            }
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
        />
        <Box sx={{my:'2%'}}>
            <Typography sx={{fontWeight:'bold',my:'3%',textAlign:'center'}}>OR</Typography>
        </Box>
        <Box sx={{my:'2%'}}>
            <TextField
                fullWidth
                type='number'
                size="small"
                label="Enter Registered Number"
                sx={{mb:'2%'}}
                disabled={watch('email')}
                {
                    ...register(
                        'phoneNumber', {
                            pattern: {
                                value: /^[1-9]{1}[0-9]{9}$/,
                                message: "Only numeric, not starting with 0"
                            },
                            minLength: {
                                value: 10,
                                message: "Contact should be of 10 digits"
                            },
                            maxLength: {
                                value: 10,
                                message: "Contact should be of 10 digits"
                            },
                            validate: (value) => {
                                const email = getValues('email');
                                if (!value && !email) {
                                    return 'Either email or phone number is required';
                                }
                                return true;
                            }
                        }
                    )
                }
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber && errors.phoneNumber?.message}
            />
        </Box>
        <Box sx={{my:'2%', textAlign:'center'}}>
            <Button variant='contained' color='primary' type='submit'>Find Account</Button>
        </Box>
        
    </Box>
    <Box sx={{borderTop:'2px solid' ,p:'2%', textAlign:'center'}}>
        {/* <Link href='/account/forgot-password' className='text-[#0866ff]'>Cancel</Link> */}
        <Typography>Want to try login again ?...
            <span>
                <Link component={RouterLink} to="/login" underline='hover' sx={{color:'#0866ff', fontWeight:'bold'}}>Go Back To Login</Link>
            </span>
        </Typography>
    </Box>
    </>
  )
}

export default SearchAccount
